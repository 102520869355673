<template>
    <div>
        <div class="breadcrumb-ctn">
            <a-breadcrumb>
                <a-breadcrumb-item v-for="(route, index) in breadcrumbData" :key="route.path">
                    <router-link v-if="route.path" :to="route.path">
                        {{ route.breadcrumbName }}
                    </router-link>
                    <span v-else>
                        {{ route.breadcrumbName }}
                    </span>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div style="display: flex;">
            <div class="left-content">
                <div style="position: sticky; top: 32px;">
                    <div class="left-icon-ctn">
                        <a-badge :count="postData.paperInfo.likeCount" :dot="false"
                            :class="[postData.paperInfo.hasLike == 1 ? 'active-badge' : '']">
                            <div class="left-icon" @click="handleToggleLike">
                                <svg-icon name="thumbs-up"
                                    :class="['operation-icon', postData.paperInfo.hasLike == 1 ? 'thumbs-up-active' : 'thumbs-up-inactive', getThumbsClass]">
                                </svg-icon>
                            </div>

                        </a-badge>
                    </div>
                    <div class="left-icon-ctn">
                        <a-badge :count="postData.paperInfo.commentCount" :dot="false">
                            <div class="left-icon" @click="handleGoToComment">
                                <a-tooltip placement="top" :title="/* isCollocted ? '取消收藏' : '收藏' */ '评论'">
                                    <svg-icon name="chat-1-fill" :class="['operation-icon', 'chat-1-fill']"></svg-icon>
                                </a-tooltip>
                            </div>

                        </a-badge>
                    </div>
                    <div class="left-icon-ctn">
                        <a-badge :count="0" :dot="false">
                            <div class="left-icon" @click="toggleCollect">
                                <a-tooltip placement="top" :title="postData.paperInfo.hasCollect == 2 ? '收藏' : '取消收藏'">
                                    <svg-icon name="bbs-star"
                                        :class="['operation-icon', 'chat-1-fill', postData.paperInfo.hasCollect == 1 ? 'collected-icon' : ' uncollocted-icon']"></svg-icon>
                                </a-tooltip>
                            </div>

                        </a-badge>
                    </div>

                </div>
            </div>
            <div class="main-content">
                <div v-if="postData.userInfo.id">
                    <div class="post-ctn">
                        <div class="post-title-ctn">
                            <div class="post-title">
                                <span> {{ postData.paperInfo.title }} </span>
                                <div v-if="postData.paperInfo.groupId != 0"
                                    style=" background: #31B588; color: #fff; font-size: 12px; padding:2px 4px;border-radius: 4px; height: 20px; margin-left: 10px; align-self: center; display: flex; align-items: center;">
                                    {{ postData.paperInfo.groupName }} </div>
                            </div>

                        </div>
                        <div class="post-user-info-ctn">
                            <a class="avatar-link" :href="getUserHref" target="_blank">
                                <DefaultAvatar :size="48" :name="postData.userInfo.name" :url="postData.userInfo.avatar">
                                </DefaultAvatar>
                            </a>
                            <div style="margin-left: 10px; flex: 1;">
                                <div style="display: flex;">
                                    <div style="color:rgba(0, 0, 0, 0.85); font-size: 16px;  line-height: 24px;">
                                        {{ postData.userInfo.name }}
                                    </div>
                                    <div
                                        class="post-user"
                                        style="display: flex; align-items: center;  padding: 2px 7px; color: #fff; border-radius:3px; font-size: 11px; height: 22px; margin-left: 10px;">
                                        楼主
                                    </div>
                                </div>
                                <div style="margin-top: 7px; font-size: 12px; color: rgba(0, 0, 0, 0.45);">
                                    发布于 {{ postData.paperInfo.publishedTime }}
                                </div>
                            </div>
                            <div v-if="!isSelf">
                                <a-button style="width: 110px;" v-if="postData.userInfo.hasFollow == 1" :disabled="isFollowing"
                                    :loading="isFollowing" @click="follow" type="primary"> + 关注 </a-button>
                                <a-button style="width: 110px;" v-if="postData.userInfo.hasFollow !== 1" :disabled="isFollowing"
                                    :loading="isFollowing" @click="unfollow"  @mouseover="(e) => handleRecentSubscribeMouseIn(e)"
                                    @mouseleave="(e) => handleRecentSubscribeMouseLeave(e)" class="unsub-btn">
                                    <span v-if="isHover">取消关注 </span> 
                                    <span v-else>
                                        <a-icon v-if="postData.userInfo.hasFollow === 3" type="swap" /> 
                                        {{ postData.userInfo.hasFollow === 2 ? '已关注' : '互相关注' }}
                                    </span>
                                </a-button>
                            </div>
                        </div>
                        <div class="post-html-content" style="margin-top: 20px;" @oncopy="listenCopyContent">
                            <div v-html="postData.paperInfo.content">

                            </div>
                        </div>
                        <div v-if="postData.topicInfo && postData.topicInfo.id != 0" class="from-topic-ctn">
                            <div style="color: #3D3D3D; ">
                                来自于话题：
                            </div>
                            <div class="from-topic-title">
                                <a :href="getTopicHref" target="_blank"> # {{ postData.topicInfo.subjectName }} <a-icon
                                        type="right" style="margin-left: 9px; font-size: 12px;" /></a>
                            </div>
                        </div>
                    </div>
                    <div ref="commentDiv" id="comment" class="comment-ctn">
                        <div class="my-comment">
                            <div style="color: rgba(0, 0, 0, 0.85); font-size: 16px; font-weight: bold;">
                                评论
                            </div>
                            <div style="margin-top: 20px;display: flex;">
                                <div>
                                    <DefaultAvatar :name="userInfo.name" :url="userInfo.photoMedia" :size="40">
                                    </DefaultAvatar>
                                </div>
                                <div style="margin-left: 12px; flex: 1; width: 0px;">
                                    <div>
                                        <a-tooltip
                                            :content="postData.paperInfo.allowedComment != 1 && !isSelf ? '该文章禁止评论' : ''">
                                            <a-input v-model="commentContent" autoComplete="off"
                                                :disabled="postData.paperInfo.allowedComment !== 1 && !isSelf"
                                                type="textarea" placeholder="输入评论" style="width: 100%;">

                                            </a-input>
                                        </a-tooltip>
                                    </div>
                                    <div style="margin-top: 12px; text-align: right;">
                                        <a-button type="primary" :disabled="!commentContent.toString().trim()"
                                            :loading="isSubmittingComment" @click="handleSubmitComment"> 发表评论 </a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="all-comment">
                            <div class="all-comment-header">
                                <div style="width: 0; flex: 1; height: 48px;">
                                    全部评论 <span> ({{ commentTotal }}) </span>
                                </div>

                              <!--   <div style="height: 48px;">
                                    <div class="order-component" :style="{ '--left': orderBeforeLeft }">
                                        <div :class="['order-item', 'order-hot', currentOrder === 'hot' ? 'active-order' : '']"
                                            @click="handleHotClick">
                                            热度
                                        </div>
                                        <a-tooltip :title="timeOrderTooltip">
                                            <div :class="['order-item', 'order-time', currentOrder === 'time' ? 'active-order' : '']"
                                                @click="handleTimeOrderClick">
                                                时间
                                                <div
                                                    style="display: flex; flex-direction: column;margin-left: 3px; justify-content: center;">
                                                    <svg-icon name="bbs-sort-arrow-up"
                                                        :class="['sort-icon', currentOrder === 'time' && isIncrease ? 'active-order-icon' : '']"></svg-icon>
                                                    <svg-icon name="bbs-sort-arrow-down"
                                                        :class="['sort-icon', currentOrder === 'time' && !isIncrease ? 'active-order-icon' : '']"></svg-icon>
                                                </div>
                                            </div>
                                        </a-tooltip>
                                    </div>
                                </div> -->
                            </div>
                            <a-spin :spinning="isCommentLoading">
                                <div v-if="isCommentLoading && commentList.length == 0" style="height: 400px;">

                                </div>
                                <div v-else>
                                    <div v-for="(comment, commentIndex) in commentList" :key="comment.id">
                                        <div>
                                            <Comment :commentData="comment" @onComment="onComment"
                                                @onCheckAll="onCheckAll(comment)" :isPostUser="isSelf"></Comment>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!isCommentLoading && commentList.length == 0" style="padding: 80px 50px;">
                                    <a-empty description="暂无评论"></a-empty>
                                </div>
                                <div class="pagination-ctn default-pagination">
                                    <a-pagination @change="handlePageChange" v-model="commentCurrent"
                                        :pageSize="commentPageSize" :total="oneLevelCommentCount" show-quick-jumper
                                        hideOnSinglePage />
                                </div>
                            </a-spin>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-content">
                <div v-if="postData.paperInfo.groupId && postData.paperInfo.groupId !== 0"
                    style="margin-bottom: 20px; border: 1px solid #EBEBEB; background: #fff;">
                    <div style="width: 100%;">
                        <div style="display: flex; align-items: center; padding: 20px; border-bottom: 1px solid #EBEBEB;">
                            <!-- <ResearchGroupIcon :name="postData.paperInfo.groupName"></ResearchGroupIcon> -->
                            <ResearchGroupIcon :name="'测试课题组'"></ResearchGroupIcon>
                            <div style="margin-left: 10px;">
                                <div style=" color: rgba(0, 0, 0, 0.85);">
                                    <div v-if="!groupInfo">
                                        {{ ' ' }}
                                    </div>
                                    <div v-else>
                                        <a class="grouName-a" :href="getGroupPostHref(postData.paperInfo.groupId)"
                                            target="_blank">
                                            {{ groupInfo.groupName }}
                                        </a>
                                    </div>
                                </div>
                                <div style="color: rgba(0, 0, 0, 0.45); font-size: 12px; ">
                                    今日更新： <a-icon v-if="!groupInfo" type="loading" /> <span v-else> {{
                                        groupInfo.publishedPaperCount }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div
                            style="width: 40%; flex: 1; padding: 12px; display: flex; flex-direction: column; justify-content: center; align-items: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                            <div>
                                总贴数
                            </div>
                            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 100%; text-align: center; margin-top: 4px; color: #4E5969; font-size: 20px;"
                                :title="groupInfo && groupInfo.allPaperCount">
                                <span v-if="!groupInfo">
                                    <a-icon type="loading" />
                                </span>
                                <span v-else>
                                    {{ groupInfo.allPaperCount }}
                                </span>
                            </div>
                        </div>
                        <div style="background-color: #ebebeb; width: 1px;">
                        </div>
                        <div
                            style="width: 40%; flex: 1; padding: 12px; display: flex; flex-direction: column; justify-content: center; align-items: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                            <div>
                                人数
                            </div>
                            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 100%; text-align: center; margin-top: 4px; color: #4E5969; font-size: 20px;"
                                :title="groupInfo && groupInfo.userCount">
                                <span v-if="!groupInfo">
                                    <a-icon type="loading" />
                                </span>
                                <span v-else>
                                    {{ groupInfo.userCount }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="postData.userInfo.id"
                    style=" background: #fff; border: 1px solid #EBEBEB; padding: 16px 20px; display: flex;">
                    <div>
                        <a :href="getUserHref" target="_blank">
                            <DefaultAvatar :name="postData.userInfo.name" :url="postData.userInfo.avatar" :size="48">
                            </DefaultAvatar>
                        </a>
                    </div>
                    <div style="margin-left: 10px;">
                        <div style="color: rgba(0, 0, 0, 0.65);">
                            {{ postData.userInfo.name }}
                        </div>
                        <div style="color: rgba(0, 0, 0, 0.65); margin-top: 5px;">
                            {{ postData.userInfo.biography }}
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px">
                    <SideGapCard title="相关阅读" gapColor="#52C41A">
                        <template>
                            <a-skeleton :loading="isRelativePostLoading" active :title="false"
                                :paragraph="{ rows: 10, width: '100%' }"
                                :style="{ padding: isRelativePostLoading ? '16px' : '0px' }">
                                <div class="recomand-list">
                                    <a v-for="(item, index) in relativePostList" :href="getPostHref(item.id)"
                                        target="_blank"
                                        style="padding: ; color: rgba(0, 0, 0, 0.65); display: flex; align-items: center;"
                                        class="relative-post">
                                        <div style="background: #D8D8D8; width: 4px; height: 4px; border-radius: 50%;">

                                        </div>
                                        <div style="margin-left: 7px; flex: 1; width: 0px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
                                            :title="item.title">
                                            {{ item.title }}
                                            <!-- 急性脑梗死常用治疗药物速览急性脑梗死常用治疗药物速览 -->
                                        </div>
                                    </a>
                                </div>
                                <a-empty v-if="relativePostList.length === 0" style="margin: 20px;"></a-empty>
                            </a-skeleton>
                        </template>
                    </SideGapCard>
                </div>
                <div v-if="dirsList.length > 0"
                    style=" background: #fff; border: 1px solid #EBEBEB; margin-top: 20px; position: sticky; top: 32px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04); ">
                    <div
                        style="padding: 10px 12px; display: flex; border-bottom:  1px solid #EBEBEB; font-size: 16px; font-weight: bold;">
                        目录
                    </div>
                    <div style="max-height: 400px; overflow-y: auto;padding-bottom: 20px;">
                        <div v-for="(dir, index) in dirsList"
                            style="margin-top: 12px; padding-left: 16px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                            :title="dir.title" :class="[activeDirId == dir.id ? 'active-dir' : 'inactive-dir']">
                            <a :href="`/#${$route.path}#${dir.id}`" @click="handleScrollDir(dir)"
                                :style="{ marginLeft: `${dir.level * 12}px`, color: 'inherit' }">
                                {{ dir.title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-back-top />
        <CheckAllCommentModal v-if="checkAllCommentModalVisible" :visible.sync="checkAllCommentModalVisible"
            :paperId="$route.params.id" :commentData="checkAllComment" :isPostUser="isSelf" @onDelete="onCommentDelete"
            @onChildCommentDelete="onChildCommentDelete"></CheckAllCommentModal>
    </div>
</template>

<script>
import { computed, onBeforeMount, ref, nextTick } from 'vue-demi';
import { getPaperInfo, getGroupPostInfo, likePost, unLikePost, collectPost, unCollectPost, getCommentInfo, replyComment, followUser, unFollowUser, getRecommendedPaper } from '@/api/paperManage.js';
import DefaultAvatar from '../../../components/defaultAvatar.vue';
import store from '../../../store';
import ResearchGroupIcon from '../components/researchGroupIcon.vue';
import throttle from 'lodash/throttle';
import { message } from 'ant-design-vue';
import Comment from './components/comment.vue';
import CheckAllCommentModal from './components/checkAllCommentModal.vue';
import { useIntersectionObserver } from '@vueuse/core';
import debounce from 'lodash/debounce';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import SideGapCard from '../components/sideGapCard.vue';

export default {
    props: {},
    components: {
        DefaultAvatar,
        ResearchGroupIcon,
        Comment,
        CheckAllCommentModal,
        SideGapCard
    },
    setup(props, context) {
        const { $route, $router } = context.root;

        const breadcrumbData = computed(() => {
            const res = [
                {
                    path: '/bbs/index',
                    breadcrumbName: '论坛首页'
                },
                /*   {
  
                  },
                  {
                      path: href.href,
                      breadcrumbName: `# ${subjectDetail.value.subjectName}`
                  } */
            ]
            if (postData.value.paperInfo.groupId && postData.value.paperInfo.groupId) {
                const groupHref = $router.resolve({
                    name: 'researchTeamPost',
                    query: {
                        id: postData.value.paperInfo.groupId
                    }
                })
                //console.log(groupHref)
                res.push({
                    path: groupHref.route.fullPath,
                    breadcrumbName: postData.value.paperInfo.groupName
                })
            }

            res.push({
                path: '',
                breadcrumbName: '帖子详情'
            })

            return res
        });

        const isLoadingPaper = ref(false);  // post数据是否正在加载

        // 获取帖子数据
        const postData = ref({
            paperInfo: {

            },
            userInfo: {

            }
        })

        const userInfo = computed(() => {
            return store.state.userInfo;
        })

        const getPostData = async () => {
            isLoadingPaper.value = true;
            const res = await getPaperInfo({
                paperid: $route.params.id
            });
            if (res.success) {
                postData.value = res.data;
                document.title = postData.value.paperInfo.title
                if (postData.value.paperInfo.groupId != 0) {
                    getGrouInfo();
                }
                nextTick(() => {
                    getDirs();
                    /* const allImage = document.querySelectorAll('.post-html-content img');
                    allImage.forEach(img => {
                        img.setAttribute('preview', 1)
                        img.setAttribute('preview-text', 'image')
                    }) */
                    interceptLink();
                    const gallery = new Viewer(document.querySelector('.post-html-content'), {
                        toolbar: {
                            oneToOne: true,
                        },
                       /*  filter(image) {
                            return image.complete;
                        },
                        url(image) {
                            console.log(image.src)
                            return image.src.replace('?size=160', '');
                        }, */
                    });
                    listenCopyContent();
                    //console.log(gallery)
                    // gallery.show()
                })
            }
            nextTick(() => {
                if ($route.fullPath.indexOf('#comment') != -1) {
                    commentDiv.value && commentDiv.value.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }

            })


            isLoadingPaper.value = false;
        }



        onBeforeMount(() => {
            getPostData();
            loadComment();
            initRelativePost();
        })

        // 获取楼主href
        const getUserHref = computed(() => {
            if (!postData.value.userInfo.id) {
                return ''
            }
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: isSelf.value ? 0 : postData.value.userInfo.id
                }
            })
            return href.href
        })

        // 用户是否为自己
        const isSelf = computed(() => {
            return postData.value.userInfo.id == userInfo.value.userId;
        })

        const isGroupInfoLoading = ref(false);

        const groupInfo = ref(null)

        const getGrouInfo = async () => {
            const res = await getGroupPostInfo({
                groupid: postData.value.paperInfo.groupId
            });
            if (res.success) {
                groupInfo.value = res.data;
            }
        }

        const handleGoToComment = () => {
            commentDiv.value && commentDiv.value.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }

        /*****************************          发布评论           ****************************/
        const commentContent = ref('');

        const isSubmittingComment = ref(false); // 是否正在发表评论

        const handleSubmitComment = async () => {
            isSubmittingComment.value = true;
            const res = await replyComment({
                "paperId": $route.params.id,
                "commentId": 0,
                "content": commentContent.value
            });
            if (res.success) {
                message.success('评论成功')
                commentCurrent.value = 1;
                commentContent.value = '';
                loadComment();
            }
            isSubmittingComment.value = false;
        }

        const onComment = () => {
            loadComment();
        }

        const commentDiv = ref();

        const isRecentLike = ref(false);

        const getThumbsClass = computed(() => {
            return isRecentLike.value ? "recent-like" : '';
        })


        const isLikeSubmiting = ref(false);     // 是否正在喜欢或取消喜欢

        // 点击喜欢/不喜欢
        const handleToggleLike = debounce(async () => {
            if (isLikeSubmiting.value) {
                return;
            }
            isLikeSubmiting.value = true;
            const isLike = postData.value.paperInfo.hasLike == 1;
            isRecentLike.value = false;
            if (isLike) {
                const formData = new FormData();
                formData.append('paperid', postData.value.paperInfo.id)
                const res = await unLikePost(formData);
                if (res.success) {
                    message.info('已取消点赞')
                    postData.value.paperInfo.hasLike = 2;
                    postData.value.paperInfo.likeCount = postData.value.paperInfo.likeCount - 1;
                }
            }
            else {
                const formData = new FormData();
                formData.append('paperid', postData.value.paperInfo.id)
                const res = await likePost(formData);
                if (res.success) {
                    message.success('已点赞')
                    postData.value.paperInfo.hasLike = 1;
                    postData.value.paperInfo.likeCount = postData.value.paperInfo.likeCount + 1;
                    isRecentLike.value = true;
                }
            }
            isLikeSubmiting.value = false;
        }, 300)

        const isSubmittingCollect = ref(false); // 是否正在点击收藏

        // 收藏/取消收藏
        const toggleCollect = debounce(async () => {
            if (isSubmittingCollect.value) {
                return
            }
            isSubmittingCollect.value = true;
            const isCollocted = postData.value.paperInfo.hasCollect == 1;
            if (isCollocted) {
                const formData = new FormData();
                formData.append('paperid', postData.value.paperInfo.id);
                const res = await unCollectPost(formData);
                if (res.success) {
                    message.info('已取消收藏');
                    postData.value.paperInfo.hasCollect = 2;
                }
            }
            else {
                const formData = new FormData();
                formData.append('paperid', postData.value.paperInfo.id);
                const res = await collectPost(formData);
                if (res.success) {
                    message.success('成功收藏');
                    postData.value.paperInfo.hasCollect = 1;
                }
            }
            isSubmittingCollect.value = false;
        }, 300)



        const isCommentLoading = ref(false);    // 评论是否正在加载

        const commentCurrent = ref(1);  // 评论页数

        const commentPageSize = ref(10);    // 评论每页数量

        const commentTotal = ref(0);    // 评论总数

        const commentList = ref([]);    //

        const oneLevelCommentCount = ref(); // 一级评论总数

        const loadComment = async () => {
            const res = await getCommentInfo({
                paperid: $route.params.id,
                commetpagenum: commentCurrent.value,
                commetpagesize: commentPageSize.value
            })
            if (res.success) {
                commentList.value = res.data.data;
                commentTotal.value = res.data.commentCount;
                oneLevelCommentCount.value = res.data.pCommentCount;
            }
        }

        // 评论切换
        const handlePageChange = () => {
            loadComment();
        }

        /*******************************            排序控制             *******************************/
        const currentOrder = ref("hot"); // 当前顺序： 热度| 时间
        const isIncrease = ref(false); // 是否是升序
        const orderBeforeLeft = computed(() => {
            if (currentOrder.value == "hot") {
                return "3px";
            }
            else if (currentOrder.value == "time") {
                return "58px";
            }
        });
        // 时间排序提示信息
        const timeOrderTooltip = computed(() => {
            if (currentOrder.value != "time") {
                return null;
            }
            else {
                if (isIncrease.value) {
                    return "最先发布";
                }
                else {
                    return "最晚发布";
                }
            }
        });
        // 评论点击时间排序
        const handleTimeOrderClick = () => {
            if (currentOrder.value === "hot") {
                currentOrder.value = "time";
                isIncrease.value = false;
            }
            else if (currentOrder.value) {
                isIncrease.value = !isIncrease.value;
            }
        };

        // 评论点击热度排序
        const handleHotClick = () => {
            currentOrder.value = "hot";
        };

        // 获取话题链接
        const getTopicHref = computed(() => {
            if (postData.value.topicInfo && !postData.value.topicInfo) {
                return ''
            }
            const href = $router.resolve({
                name: 'topicPost',
                query: {
                    id: postData.value.topicInfo.id
                }
            })
            return href.href
        })

        /*********************************************             查看全部评论                           ******************************/
        const checkAllComment = ref(null);    // 查看所有评论的评论Id

        const checkAllCommentModalVisible = ref(false);  // 查看所有评论对话框可见变量

        // 查看全部评论回调
        const onCheckAll = (comment) => {
            checkAllComment.value = comment;
            checkAllCommentModalVisible.value = true;
        }

        // 评论删除
        const onCommentDelete = () => {
            checkAllCommentModalVisible.value = false;
            loadComment()
        }

        // 子级删除回调
        const onChildCommentDelete = () => {
            loadComment()
        }

        /*********************************     关注用户           *******************************/
        const isFollowing = ref(false);  // 是否正在关注中

        // 关注用户
        const follow = async () => {
            isFollowing.value = true;
            const res = await followUser({
                userid: postData.value.userInfo.id
            });
            if (res.success) {
                message.success('成功关注')
                postData.value.userInfo.hasFollow = 2
            }
            isFollowing.value = false;
        }
        // 取消关注
        const unfollow = async () => {
            isFollowing.value = true;
            const formData = new FormData();
            formData.append('userid', postData.value.userInfo.id)
            const res = await unFollowUser(formData);
            if (res.success) {
                postData.value.userInfo.hasFollow = 1
                message.info('已取消关注')
            }
            isFollowing.value = false;
        }

        // 前往课题组帖子的页面链接
        const getGroupPostHref = (id) => {
            const res = $router.resolve({
                name: 'researchTeamPost',
                query: {
                    id: id
                }
            })
            return res.href;
        }

        /*********************                目录结构               *************************/
        const dirsList = ref([]);   // 获取目录结构

        const dirElements = ref([]);    // 目录结构列表

        // 目录结构优先级
        const tagPriority = {
            'H1': 3,
            'H2': 2,
            'H3': 1
        }

        // 获取文章最高级的元素属性: h2/h3已经是最高级目录结构
        const getFirstLevelTagName = () => {
            const nodeNames = [];
            for (let i = 0; i < dirElements.value.length; ++i) {
                nodeNames.push(dirElements.value[i].nodeName);
            }
            const h1Index = nodeNames.indexOf('H1');    // H1的索引
            const h2Index = nodeNames.indexOf('H2');
            const h3Index = nodeNames.indexOf('H3');
            if (h1Index !== -1) {
                return {
                    name: 'H1',
                    index: h1Index
                }
            }
            else if (h2Index !== -1) {
                return {
                    name: 'H2',
                    index: h2Index
                }
            }
            else if (h3Index !== -1) {
                return {
                    name: 'H3',
                    index: h3Index
                }
            }
            return null
        }
        const activeDirId = ref('')

        const getDirs = () => {
            // 获取文章内容所有的H1，H2, H3元素， 即目录元素
            const dirs = document.querySelectorAll('.post-html-content h1, .post-html-content h2,.post-html-content h3');
            dirElements.value = dirs;
            // 当目录元素为空返回
            if (!dirs || dirs.length === 0) {
                return
            }

            // 获取目录元素中的一级目录元素tag名，以及目录的起始元素索引
            const firstLevelTagInfo = getFirstLevelTagName();   // 获取一级目录的tagName

            // 如果不存在tag名
            if (!firstLevelTagInfo) {
                return;
            }

            // 获取第一级的优先级
            const firstLevelPriority = tagPriority[firstLevelTagInfo.name];



            //const 
            // 从目录起始元素索引开始往下的所有元素都是有效元素
            for (let i = firstLevelTagInfo.index; i < dirs.length; ++i) {
                const id = `dir-header-${dirsList.value.length}`
                dirs[i].setAttribute('id', id);
                //&nbsp
                dirsList.value.push({
                    title: `${Array.from({ length: firstLevelPriority - tagPriority[dirs[i].nodeName] }, () => '').join('')}${dirs[i].textContent.trim()}`, // 根据目录元素优先级，决定当前元素前面的空格
                    level: firstLevelPriority - tagPriority[dirs[i].nodeName],  // 根据一级元素的优先级确定为几级目录
                    id: id,
                    el: dirs[i] // 对应的元素
                })

            }
            for (let i = 0; i < dirsList.value.length; ++i) {
                // 对所有的目录元素进行可见监控
                useIntersectionObserver(dirsList.value[i].el, ([{ isIntersecting }], observerElement) => {
                    if (isIntersecting && !isScrolling.value) {
                        //activeDirId.value = dirsList.value[i].id
                        setActiveId(dirsList.value[i].id)
                    }
                })
            }
            // console.log(dirsList.value)
        }

        const setActiveId = throttle((id) => {
            activeDirId.value = id;
        }, 500)

        const isScrolling = ref(false);

        // 点击后跳转到指定的目录元素位置
        const handleScrollDir = async (dir) => {
            isScrolling.value = true;
            await dir.el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
            setActiveId(dir.id);

            setTimeout(() => {
                isScrolling.value = false;
            }, 500)
            //document.querySelector
        }

        // 外链拦截跳转
        const interceptLink = () => {
            const allATag = document.querySelectorAll(".post-html-content a");
            const currentHost = window.location.host;
            allATag && allATag.forEach(aTag => {
                try {
                    const url = new URL(aTag['href']);
                    // 不是当前的域名则为外链，进行跳转拦截
                    if (url.host != currentHost) {
                        const href = $router.resolve({
                            name: 'linkIntercept',
                            query: {
                                url: decodeURI(url.href)
                            }
                        })
                        aTag.setAttribute('href', href.href);
                        aTag.target = '_blank';
                    }
                    else {
                        aTag.target = '_blank';
                    }
                } catch (error) {
                    // 如果href属性不为URL，则去除该属性
                    aTag.removeAttribute('href');
                }
                
            })
        }
        /************************                     获取相关阅读                  ***************************************/
        const isRelativePostLoading = ref(false);    // 是否正在加载相关阅读

        const relativePostList = ref([]);   // 相关阅读列表

        // 获取文章链接
        const getPostHref = (id) => {
            const href = $router.resolve({
                name: 'bbsPost',
                params: {
                    id: id
                }
            });
            return href.href
        }

        // 初始化相关阅读列表
        const initRelativePost = async () => {
            isRelativePostLoading.value = true;
            const res = await getRecommendedPaper({
                paperid: $route.params.id
            });
            if (res.success) {
                relativePostList.value = res.data;
            }
            isRelativePostLoading.value = false;
        }



        // 监听复制事件
        const listenCopyContent = () => {
            const content = document.querySelector('.post-html-content');
            content && content.addEventListener('copy', function (event) {
                const selection = window.getSelection();
                if (selection.toString().length < 50) {
                    return
                }
                const cloneFragement = selection.getRangeAt(0).cloneContents();
                const anthorEle = document.createElement('div');
                anthorEle.append(cloneFragement);
                anthorEle.innerHTML = `
                    ${anthorEle.innerHTML}

                    <br/>

                    <div>
                        作者： ${postData.value.userInfo.name}
                    </div>
                    <div>
                        链接：${window.location.href}
                    </div>
                    <div>
                        来源：科颐轩社区
                    </div>
                    <div>
                        著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
                    </div>
                `
                cloneFragement.appendChild(anthorEle)
                anthorEle.style.position = 'fixed';
                anthorEle.style.left = '110vh';
                document.documentElement.append(anthorEle);
                selection.selectAllChildren(anthorEle)
                setTimeout(() => {
                    anthorEle.remove();
                }, 2000)
                //copyEle.innerHTML = `${selection.anchorNode}`

                //复制文本内容转大写
                //event.clipboardData.setData('text/plain', `${selection.toString()} \n\n\n作者： ${ postData.value.userInfo.name},\n链接：${window.location.href}, \n来源：科颐轩社区, \n著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。`);

            })
        }

        const isHover = ref(false);

        // 鼠标移动至已关注/互相关注按钮上
        const handleRecentSubscribeMouseIn = () => {
            isHover.value = true;
        }
        // 鼠标移出
        const handleRecentSubscribeMouseLeave = () => {
            isHover.value = false;
        }

        return {
            breadcrumbData,
            getPostData,
            getThumbsClass,
            postData,
            getUserHref,
            isSelf,
            isGroupInfoLoading,
            groupInfo,
            commentContent,
            userInfo,
            isSubmittingComment,
            handleSubmitComment,
            commentDiv,
            handleGoToComment,
            handleToggleLike,
            toggleCollect,
            isCommentLoading,
            toggleCollect,
            loadComment,
            isCommentLoading,
            commentList,
            commentTotal,
            commentCurrent,
            currentOrder,
            isIncrease,
            orderBeforeLeft,
            timeOrderTooltip,
            handleTimeOrderClick,
            handleHotClick,
            getTopicHref,
            onComment,
            onCheckAll,
            checkAllComment,
            checkAllCommentModalVisible,
            handlePageChange,
            commentPageSize,
            oneLevelCommentCount,
            follow,
            isFollowing,
            unfollow,
            isRecentLike,
            getGroupPostHref,
            dirsList,
            handleScrollDir,
            activeDirId,
            onCommentDelete,
            onChildCommentDelete,
            isRelativePostLoading,
            relativePostList,
            getPostHref,
            listenCopyContent,
            isHover,
            handleRecentSubscribeMouseIn,
            handleRecentSubscribeMouseLeave
            // $route
        }
    }
}
</script>

<style lang="less" scoped>
.breadcrumb-ctn {
    margin-top: 12px;
    padding-left: 80px;
    //width: calc(100% - 300px);
}

.left-content {
    margin-right: 32px;
    padding-top: 50px;


    /deep/ .ant-badge-count {
        background: #AAA;
        color: #fff;
    }

    /deep/ .active-badge .ant-badge-count {
        background: @srims-primary-color;
        color: #fff;
    }

    .left-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.9;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        .operation-icon {
            fill: #8590A6;
            font-size: 20px;

            &:focus {
                outline: none;
            }
        }

        .collected-icon {
            fill: #FFC34D;
        }

        .thumbs-up-inactive {
            //transition: .3s all ease-in-out;
            transform: rotate(0deg) translateY(0px);
        }

        .recent-like {
            fill: @srims-primary-color;

            animation: thumbsUp .5s ease-in-out;
        }

        .thumbs-up-active {
            fill: @srims-primary-color;
        }
    }

    .left-icon-ctn+.left-icon-ctn {
        margin-top: 24px;
    }

}

.main-content {
    max-width: 850px;
    width: 0px;
    flex: 1;

    .post-ctn {
        margin-top: 12px;
        padding: 20px;
        background: #fff;
        border: 0.5px solid #EBEBEB;

        .post-title-ctn {
            .post-title {
                font-size: 24px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: bold;
                line-height: 32px;
                display: flex;
                flex-wrap: wrap;
                word-break: break-all;
                letter-spacing: 0.05em;
            }
        }

        .post-user-info-ctn {
            display: flex;
            padding: 16px 0px;
            align-items: center;
            border-bottom: 1px solid #EBEBEB;
            .post-user {
                background: @srims-primary-color;
            }
        }
    }

    .from-topic-ctn {
        margin-top: 28px;
        background: #F6F6F6;
        padding: 12px 20px;

        .from-topic-title {
            margin-top: 10px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: #1890ff;
                }
            }

            /* cursor: pointer;
            &:hover {
                
            } */
        }
    }

    .comment-ctn {
        margin-top: 20px;
        padding: 20px;
        background: #fff;
        border: 0.5px solid #EBEBEB;
        margin-bottom: 50px;

        /deep/ .ant-input {
            background: #EBEBEB;

            &:focus {
                background: white;
            }

        }

        /deep/ .ant-input-search-icon {
            font-size: 20px !important;
        }
    }

    .all-comment {
        margin-top: 32px;

        .all-comment-header {
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            border-bottom: 1px solid #ebebeb;
        }
    }
}

.right-content {
    margin-left: 20px;
    padding-top: 12px;
    width: 330px;

    .active-dir {
        color: #1890ff;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 5px;
            background: #1890ff;
        }
    }

    .inactive-dir {
        position: relative;
    }

    .relative-post {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .relative-post+.relative-post {
        margin-top: 16px;
    }
}

.pagination-ctn {
    padding: 25px;
    text-align: center;
    //border-left: .5px solid #ebebeb;
    // border-right: .5px solid #ebebeb;
    //border-bottom: .5px solid #ebebeb;
}

@keyframes thumbsUp {
    0% {
        fill: #8590A6;
        transform: rotate(0deg) translateY(0px);
    }

    50% {
        transform: rotate(-30deg) translateY(-6px);
    }

    100% {
        fill: @srims-primary-color;
        transform: rotate(0deg) translateY(0px);
    }
}
</style>

<style lang="less" scoped></style>

<style lang="less" scoped>
.order-component {
    display: flex;
    align-items: center;
    padding: 3px;
    /* --left: 3px; */
    position: relative;
    z-index: 300;
    height: 30px;
    margin-top: 7px;
    margin-right: 10px;
    border-radius: 2px;
    font-size: 14px;

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: '';
        background: #F6F6F6;
        z-index: -100;
    }

    &::after {
        position: absolute;
        width: 55px;
        height: calc(100% - 6px);
        left: var(--left);
        top: 3px;
        content: '';
        background: #fff;
        z-index: -100;
        transition: .3s left ease-in-out;
    }

    .order-item {
        width: 55px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.65);
        transition: .3s all ease-in-out;

        &:hover {
            opacity: 0.8;
            color: @srims-primary-color;
        }

        .sort-icon {
            fill: rgba(0, 0, 0, 0.65);
            font-size: 7px;
        }

        .active-order-icon {
            fill: @srims-primary-color;
        }
    }

    .active-order {
        color: rgba(0, 0, 0, 0.85);
    }
}

.grouName-a {
    color: inherit;

    &:hover {
        text-decoration: underline;
    }
}

.post-html-content {
    /deep/ img {
        cursor: zoom-in;
    }
}

.unsub-btn {
            background: #EEEEEE;
            border: .5px solid transparent;
            color: rgba(0, 0, 0, 0.65);
            transition: .3s all ease-in-out;
            &:hover {
                border: .5px solid @srims-primary-color;
            color: @srims-primary-color;
            }
        }
</style>
<!-- 
<style src="./css/contents.css">
</style> -->


<style  >
h1 {
    font-size: 1.7em;
}

h1,
h2,
h3 {
    font-weight: bold !important;
}

.post-html-content {
    color: rgba(0, 0, 0, 0.85);

}

img {
    max-width: 100% !important;
    height: auto !important;

}
</style>