<template>
    <div>
        <div style="display: flex; padding: 6px; " class="child-comment-ctn" @click="handleCommentClick">
            <a :href="getUserHref(commentData.userId)" target="_blank" style="color: #597EF7; ">{{ commentData.userName }}</a>
            <div v-if="showReplyUser"> <span> 回复 </span> <a :href="getUserHref(parentData.userId)"
                    style="color: #597EF7; ">{{ parentData.userName }}</a> </div>
            <span style="margin: 0 4px;"> :</span>
            <span style="color: rgba(0,0,0,0.65);"> {{ commentData.content }} </span>
        </div>
        <div v-show="isInputShow" style="margin-top: 12px;">
            <a-spin :spinning="isCommentSubmitting">
                <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef"
                    @blur="isCommentClick = false" @focus="isCommentClick = true"> </a-input>
                <div style="text-align: right; margin-top: 12px;">
                    <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting"
                        @click="handleSubmitComment"> 发布 </a-button>
                </div>
            </a-spin>
        </div>
        <div v-if="commentData && commentData.commentInfos && commentData.commentInfos.length > 0">
            <div v-for="(comment, commentIndex) in commentData.commentInfos" :key="comment.id">
                <ChildComment :commentData="comment" :parentData="commentData" @onComment="onComment" ></ChildComment>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, nextTick } from 'vue-demi';
import store from '../../../../store';
import {  replyComment } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
export default {
    props: {
        commentData: {
            default: () => ({})
        },
        // 是否显示回复人
        showReplyUser: {
            default: false
        },
        parentData: {
            default: () => ({})
        },
        showChechMore: {
            default: false
        },
        // 是否是楼主
        isPostUser: {
            default: false
        }
    },
    setup(props, context) {

        const { $router } = context.root;

        const isSelf = (id) => {
            return store.state.userInfo.userId === id
        }

        const getUserHref = (id) => {
            let realId = id;
            if (isSelf(id)) {
                realId = 0;
            }
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: realId
                }
            })
            return href.href
        }

        const { $route } = context.root;

        const isCommentClick = ref(false);
        const commentContent = ref('');

        const isInputShow = computed(() => {
            return isCommentClick.value || commentContent.value;
        })

        const isCommentSubmitting = ref(false);

        const handleCommentClick = () => {
            isCommentClick.value = true;
            nextTick(() => {
                inputRef.value && inputRef.value.focus();
            })

        }

        const inputRef = ref();

        // 提交评论
        const handleSubmitComment = async () => {
            isCommentSubmitting.value = true;
            const res = await replyComment({
                "paperId": $route.params.id,
                "commentId": props.commentData.id,
                "content": commentContent.value
            });
            if (res.success) {
                message.success('评论成功');
                context.emit('onComment')
                commentContent.value = '';
                isCommentClick.value = false;
            }
            isCommentSubmitting.value = false;
        }

        const onComment = () => {
            context.emit('onComment')
        }

        return {
            isSelf,
            getUserHref,
            isCommentClick,
            handleCommentClick,
            commentContent,
            isInputShow,
            inputRef,
            isCommentSubmitting,
            handleSubmitComment,
            onComment
        }
    }
}
</script>

<style lang="less" scoped>
.child-comment-ctn {
    &:hover {
        cursor: pointer;
        background: #ebebeb;
    }

}</style>