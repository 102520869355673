<template>
    <div style="padding: 20px; border-bottom: 1px solid  #EBEBEB;">
        <div class="comment-header">
            <a :href="getUserHref(commentData.userId)" target="_blank">
                <DefaultAvatar :name="commentData.userName" :url="commentData.avatar" :size="40"></DefaultAvatar>
            </a>
            <div style="margin-left: 10px; width: 0; flex: 1;">
                <div style="display: flex;">
                    <div style="width: 0; flex: 1;">
                        {{ commentData.userName }}
                    </div>
                    <div  style="margin-left: 16px;">
                        <a-button size="small" type="link" v-if="isPostUser || isSelf(commentData.userId)" @click="handleDeleteComment">
                            删除
                        </a-button>
                    </div>
                </div>

                <div class="comment-content" style="margin-top: 20px; font-size: 15px;">
                    {{  commentData.content  }}
                </div>
                <div class="comment-info" style="margin: 20px 0px; display: flex; width: 100%; color: rgba(0, 0, 0, 0.45);">
                    <div>
                        {{  commentData.createTime  }}
                    </div>
                    <div style="flex: 1;">

                    </div>
                    <div title="回复" class="reply-icon" style="display: flex; align-items: center; cursor: pointer; opacity: 0.8; color: rgba(0, 0, 0, 0.65);" @click="handleCommentClick">
                        <svg-icon name="comment-alt" class="operatin-icon comment-icon" style="fill: rgba(0, 0, 0, 0.65);;" @click="handleCommentClick" ></svg-icon>
                        <div style="margin-left: 6px;">回复</div> 
                        
                    </div>
                </div>
                <div v-show="isInputShow" >
                    <a-spin :spinning="isCommentSubmitting" >
                    <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef" @blur="isCommentClick = false" @focus="isCommentClick= true"> </a-input>
                    <div style="text-align: right; margin-top: 12px;">
                        <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting" @click="handleSubmitComment"> 发布 </a-button>
                    </div>
                </a-spin>
                </div>
                <div v-if="commentData && commentData.commentInfos && commentData.commentInfos.length > 0" class="child-comment-ctn" style="padding: 6px; background: #F6F6F6;">
                    <div v-for="(childComment, childIndex) in  commentData.commentInfos" :key="childComment.id">
                        <ChildComment :commentData="childComment" :parentData="commentData" @onComment="onComment"></ChildComment>
                        
                    </div>
                    <div class="check-more" style="display: flex;" >
                        <div style="padding: 6px; color: #597EF7; " class="check-more-operation" @click="handleCheckAll">
                            查看全部 <a-icon style="margin-left: 6px;" type="caret-down" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <!--  <div class="comment-textarea">

        </div> -->
       <!--  <div style=" height: 1px; background: #EBEBEB;">
        </div> -->
    </div>
</template>

<script>
import { computed, h, nextTick, ref } from 'vue-demi';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import {  replyComment, deleteComment } from '@/api/paperManage.js';
import { message, Modal } from 'ant-design-vue';
import ChildComment from './childComment.vue';
import store from '../../../../store';

export default {
    props: {
        commentData: {
            default: () => ({})
        },
        // 是否是发帖人
        isPostUser: {
            default: false
        }
    },
    setup(props, context) {

        const { $route, $router } = context.root;

        const isCommentClick = ref(false);
        const commentContent = ref('');

        const isInputShow = computed(() => {
            return isCommentClick.value || commentContent.value;
        })

        const isCommentSubmitting = ref(false);

        const handleCommentClick = () => {
            isCommentClick.value = true;
            nextTick(() => {
                inputRef.value && inputRef.value.focus();
            })
            
        }

        const inputRef = ref();

        // 提交评论
        const handleSubmitComment = async () => {
            isCommentSubmitting.value = true;
            const res = await replyComment({
                "paperId": $route.params.id,
                "commentId": props.commentData.id,
                "content": commentContent.value
            });
            if(res.success) {
                message.success('评论成功');
                context.emit('onComment')
                commentContent.value = '';
                isCommentClick.value = false;
            }
            isCommentSubmitting.value = false;
        }

        const onComment = () => {
            context.emit('onComment')
        }

        const handleCheckAll = () => {
            context.emit('onCheckAll')
        }

        const isSelf = (id) => {
            return store.state.userInfo.userId == id;
        }

        const getUserHref = (id) => {
            let realId = id;
            if(isSelf) {
                realId = 0;
            }
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: realId
                }
            });
            return href.href
        }

        const handleDeleteComment = () => {
            Modal.confirm({
                content: h('span', {}, [
                    h('span', {}, '是否确认删除评论 '),
                    h('span', { style: { color: '#1890ff' } }, props.commentData.content),
                    '?'
                ]),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('commentid', props.commentData.id );
                    formData.append('paperid', $route.params.id)
                    const res = await deleteComment(formData);
                    if(res.success) {
                        message.success('成功删除')
                        context.emit('onComment')
                    }
                }
            })
        }

        return {
            isCommentClick,
            handleCommentClick,
            commentContent,
            isInputShow,
            inputRef,
            isCommentSubmitting,
            handleSubmitComment,
            onComment,
            handleCheckAll,
            getUserHref,
            isSelf,
            handleDeleteComment
        };
    },
    components: { DefaultAvatar, ChildComment }
}

</script>

<style lang="less" scoped>
.comment-header {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    .reply-icon {
        &:hover {
            opacity: 1;
        }
    }
    .check-more-operation {
        cursor: pointer;
        &:hover {
            background: #EBEBEB;
        }
    }
}
</style>
