<template>
    <a-modal :visible="visible" title="评论" :width="800" :footer="null" :bodyStyle="{
        padding: '0px'
    }" @cancel="handleCancel">
        <div class="main-comment">
            <div class="comment-header">
            <a :href="getUserHref(commentData.userId)" target="_blank">
                <DefaultAvatar :name="commentData.userName" :url="commentData.avatar" :size="40"></DefaultAvatar>
            </a>
            <div style="margin-left: 10px; width: 0; flex: 1;">
                <div style="display: flex;">
                    <div style="flex: 1; width: 0; word-break: break-all;">
                        {{ commentData.userName }}
                        
                    </div>
                    <div v-if="isPostUser || isSelf(commentData.userId)">
                        <a-button size="small" type="link" v-if="isPostUser || isSelf(commentData.userId)" @click="handleDeleteComment">
                            删除
                        </a-button>
                    </div>
                </div>

                <div class="comment-content" style="margin-top: 20px; font-size: 15px;">
                    {{  commentData.content  }}
                </div>
                <div class="comment-info" style="margin: 20px 0px; display: flex; width: 100%; color: rgba(0, 0, 0, 0.45);">
                    <div>
                        {{  commentData.createTime  }}
                    </div>
                    <div style="flex: 1;">

                    </div>
                    <div title="回复" class="reply-icon" style="display: flex; align-items: center; cursor: pointer; opacity: 0.8; color: rgba(0, 0, 0, 0.65);" @click="handleCommentClick">
                        <svg-icon name="comment-alt" class="operatin-icon comment-icon" style="fill: rgba(0, 0, 0, 0.65);;" @click="handleCommentClick" ></svg-icon>
                        <div style="margin-left: 6px;">回复</div> 
                        
                    </div>
                </div>
                <div v-show="isInputShow" >
                    <a-spin :spinning="isCommentSubmitting" >
                    <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef" @blur="isCommentClick = false" @focus="isCommentClick= true"> </a-input>
                    <div style="text-align: right; margin-top: 12px;">
                        <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting" @click="handleSubmitComment"> 发布 </a-button>
                    </div>
                </a-spin>
                </div>
               <!--  <div v-if="commentData && commentData.commentInfos && commentData.commentInfos.length > 0" class="child-comment-ctn" style="padding: 6px; background: #F6F6F6;">
                    <div v-for="(childComment, childIndex) in  commentData.commentInfos" :key="childComment.id">
                        <ChildComment :commentData="childComment" :parentData="commentData" @onComment="onComment"></ChildComment>
                        
                    </div>
                    <div class="check-more" style="display: flex;" >
                        <div style="padding: 6px; color: #597EF7; " class="check-more-operation" @click="handleCheckAll">
                            查看全部 <a-icon style="margin-left: 6px;" type="caret-down" />
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        </div>
        <div style="height:10px ; background: #f6f6f6;">

        </div>
        <div class="child-comment-list" >
            <div style="padding: 16px 20px; border-bottom: 1px solid #ebebeb;">
                回复（{{ getCommentCount }}）
            </div>
            <a-spin :spinning="isLoading" >
                <div class="child-comment-list-content"  style="max-height: 500px; overflow: auto;">
                    <div v-if="childCommentDataList.length === 0" style="padding: 50px;">
                        <a-empty></a-empty>
                    </div>
                    <div v-else>
                        <div v-for="(comment, commentIndex) in childCommentDataList">
                            <ModalChildComment :parentData="commentData" :commentData="comment" :isPostUser="isPostUser" @onComment="onComment" @onDelete="onDelete"></ModalChildComment>
                        </div>
                    </div>
                </div>
            </a-spin>
        </div>
    </a-modal>
</template>

<script>
import { computed, onMounted, ref,nextTick, h } from 'vue-demi';
import { getSingleComment, deleteComment  } from '@/api/paperManage.js';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import {  replyComment } from '@/api/paperManage.js';
import ModalChildComment from './modalChildComment.vue';
import { message, Modal } from 'ant-design-vue';
import store from '../../../../store';
export default {
    props: {
        visible: {
            default: false
        },
        commentData: {
            default: () => ({})
        },
        paperId: {
            default: null
        },
        isPostUser: {
            default: false
        }
        
    },
    components: {
    DefaultAvatar,
    ModalChildComment
},
    setup(props, context) {
        const { $route, $router } = context.root;

        const isCommentClick = ref(false);
        const commentContent = ref('');

        const isInputShow = computed(() => {
            return isCommentClick.value || commentContent.value;
        })

        const isCommentSubmitting = ref(false);

        const handleCommentClick = () => {
            isCommentClick.value = true;
            nextTick(() => {
                inputRef.value && inputRef.value.focus();
            })
            
        }

        const inputRef = ref();

        // 提交评论
        const handleSubmitComment = async () => {
            isCommentSubmitting.value = true;
            const res = await replyComment({
                "paperId": $route.params.id,
                "commentId": props.commentData.id,
                "content": commentContent.value
            });
            if(res.success) {
                message.success('评论成功');
                context.emit('onComment')
                commentContent.value = '';
                isCommentClick.value = false;
                loadComment();
            }
            isCommentSubmitting.value = false;
        }

        const onComment = () => {
            loadComment();
            context.emit('onComment')
        }

        const isLoading = ref(true);

        const goDeep  = (arr) => {
            if(!arr || arr.length == 0) {
                return 0
            }
            let sum = 0;
            for(let i = 0; i < arr.length; ++i) {
                sum = sum + arr[i].commentInfos.length + goDeep(arr[i].commentInfos)
            };
            return sum
        }

        const getCommentCount = computed(() => {
            return childCommentDataList.value.length + goDeep(childCommentDataList.value)
        })

       /*  const commentData = ref({

        }) */

        const childCommentDataList = ref([])

        const loadComment = async () => {
            isLoading.value = true;
            const res = await getSingleComment({
                paperId: props.paperId,
                commentid: props.commentData.id
            });
            if(res.success) {
                childCommentDataList.value = res.data;
            }
            isLoading.value = false;
        }

        onMounted(() => {
            loadComment();
        })

        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        const isSelf = (id) => {
            return store.state.userInfo.userId == id;
        }

        const getUserHref = (userId) => {
            let realId = userId;
            if(isSelf(userId)) {
                realId = 0
            }
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: realId
                }
            });
            return href.href;
        }

        const handleDeleteComment = () => {
            Modal.confirm({
                content: h('span', {}, [
                    h('span', {}, '是否确认删除评论 '),
                    h('span', { style: { color: '#1890ff' } }, props.commentData.content),
                    '?'
                ]),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('commentid', props.commentData.id );
                    formData.append('paperid', $route.params.id)
                    const res = await deleteComment(formData);
                    if(res.success) {
                        message.success('成功删除')
                        context.emit('onDelete', false);
                    }
                }
            })
        }

        // 子评论删除回调
        const onDelete = () => {
            loadComment();
            context.emit('onChildCommentDelete')
        }


        return {
            isLoading,
            isCommentClick,
            handleCommentClick,
            commentContent,
            isInputShow,
            inputRef,
            isCommentSubmitting,
            handleSubmitComment,
            onComment,
            childCommentDataList,
            getCommentCount,
            handleCancel,
            isSelf,
            getUserHref,
            handleDeleteComment,
            onDelete
        }
    }
}
</script>

<style lang="less" scoped>
.comment-header {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    padding: 20px;
    .reply-icon {
        &:hover {
            opacity: 1;
        }
    }
    .check-more-operation {
        cursor: pointer;
        &:hover {
            background: #EBEBEB;
        }
    }
}
</style>