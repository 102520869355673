<template>
    <div >
       <!--  <div style="display: flex; padding: 6px; " class="child-comment-ctn" @click="handleCommentClick">
            <a :href="getUserHref(commentData.userId)" style="color: #597EF7; ">{{ commentData.userName }}</a>
            <div v-if="showReplyUser"> <span> 回复 </span> <a :href="getUserHref(parentData.userId)"
                    style="color: #597EF7; ">{{ parentData.userName }}</a> </div>
            <span style="margin: 0 4px;"> :</span>
            <span style="color: rgba(0,0,0,0.65);"> {{ commentData.content }} </span>
        </div>
        <div v-show="isInputShow" style="margin-top: 12px;">
            <a-spin :spinning="isCommentSubmitting">
                <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef"
                    @blur="isCommentClick = false" @focus="isCommentClick = true"> </a-input>
                <div style="text-align: right; margin-top: 12px;">
                    <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting"
                        @click="handleSubmitComment"> 发布 </a-button>
                </div>
            </a-spin>
        </div> -->
        <div class="main-comment" style="border-bottom: 1px solid #EBEBEB;">
            <div style="padding:0px 20px; padding-top: 20px; display: flex;align-items: center;">
                <div style="display: flex; width: 0; flex: 1; ">
                    <a :href="getUserHref(commentData.userId)" target="_blank">
                        <DefaultAvatar :name="commentData.userName" :url="commentData.avatar" :size="40"></DefaultAvatar>
                    </a>
                    <div style="margin-left: 10px; display: flex; width: 100%;">
                        <div style=" width: 0; flex: 1; word-break: break-all;">
                            <a :href="getUserHref(commentData.userId)" target="_blank" style="color: inherit;">
                                {{ commentData.userName }}
                            </a>
                        </div>
                        <div v-if="isSelf(commentData.userId) || isPostUser">
                            <a-button size="small" type="link" v-if="isPostUser || isSelf(commentData.userId)" @click="handleDeleteComment">
                                删除
                            </a-button>
                        </div>
                    </div>
                </div>
              <!--   <span v-if="showReplyUser" style="margin: 0 12px; color: rgba(0, 0, 0, 0.45);">
                       <a-icon type="caret-right" />
                    </span>
                <div v-if="showReplyUser" style="display: flex; ">
                    
                    <DefaultAvatar :name="parentData.userName" :url="parentData.avatar" :size="40"></DefaultAvatar>
                    <div style="margin-left: 10px;">
                        {{ parentData.userName }}
                    </div>
                </div> -->
            </div>
            <div class="comment-header">
               
           
            <div style="margin-left: 50px; width: 0; flex: 1;">
                <div  v-if="showReplyUser">
                    回复 <a :href="getUserHref(parentData.userId)" target="_blank" style="color: #597EF7; ">{{ parentData.userName }}</a> :
                </div>

                <div class="comment-content" style="margin-top: 20px; font-size: 15px;">
                    {{  commentData.content  }}
                </div>
                <div class="comment-info" style="margin: 20px 0px; display: flex; width: 100%; color: rgba(0, 0, 0, 0.45);">
                    <div>
                        {{  commentData.createTime  }}
                    </div>
                    <div style="flex: 1;">

                    </div>
                    <div title="回复" class="reply-icon" style="display: flex; align-items: center; cursor: pointer; opacity: 0.8; color: rgba(0, 0, 0, 0.65);" @click="handleCommentClick">
                        <svg-icon name="comment-alt" class="operatin-icon comment-icon" style="fill: rgba(0, 0, 0, 0.65);;" @click="handleCommentClick" ></svg-icon>
                        <div style="margin-left: 6px;">回复</div> 
                        
                    </div>
                </div>
                <div v-show="isInputShow" >
                    <a-spin :spinning="isCommentSubmitting" >
                    <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef" @blur="isCommentClick = false" @focus="isCommentClick= true"> </a-input>
                    <div style="text-align: right; margin-top: 12px;">
                        <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting" @click="handleSubmitComment"> 发布 </a-button>
                    </div>
                </a-spin>
                </div>
               <!--  <div v-if="commentData && commentData.commentInfos && commentData.commentInfos.length > 0" class="child-comment-ctn" style="padding: 6px; background: #F6F6F6;">
                    <div v-for="(childComment, childIndex) in  commentData.commentInfos" :key="childComment.id">
                        <ChildComment :commentData="childComment" :parentData="commentData" @onComment="onComment"></ChildComment>
                        
                    </div>
                    <div class="check-more" style="display: flex;" >
                        <div style="padding: 6px; color: #597EF7; " class="check-more-operation" @click="handleCheckAll">
                            查看全部 <a-icon style="margin-left: 6px;" type="caret-down" />
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        </div>
        <div v-if="commentData && commentData.commentInfos && commentData.commentInfos.length > 0">
            <div v-for="(comment, commentIndex) in commentData.commentInfos" :key="comment.id">
                <ModalChildComment :parentData="commentData" :commentData="comment" :showReplyUser="true" @onComment="onComment"></ModalChildComment>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, nextTick, h } from 'vue-demi';
import {  replyComment, deleteComment } from '@/api/paperManage.js';
import { message, Modal } from 'ant-design-vue';
import store from '../../../../store';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import ModalChildComment from './modalChildComment.vue';
export default {
    name: 'ModalChildComment',
    props: {
        commentData: {
            default: () => ({})
        },
        // 是否显示回复人
        showReplyUser: {
            default: false
        },
        parentData: {
            default: () => ({})
        },
        showChechMore: {
            default: false
        },
        // 是否是楼主
        isPostUser: {
            default: false
        }
    },
    components: {
        DefaultAvatar,
        ModalChildComment
    },
    setup(props, context) {

        const { $router } = context.root;

        const isSelf = (id) => {
            return store.state.userInfo.userId === id
        }

        const getUserHref = (id) => {
            let realId = id;
            if (isSelf(id)) {
                realId = 0;
            }
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: realId
                }
            })
            return href.href
        }

        const { $route } = context.root;

        const isCommentClick = ref(false);
        const commentContent = ref('');

        const isInputShow = computed(() => {
            return isCommentClick.value || commentContent.value;
        })

        const isCommentSubmitting = ref(false);

        const handleCommentClick = () => {
            isCommentClick.value = true;
            nextTick(() => {
                inputRef.value && inputRef.value.focus();
            })

        }

        const inputRef = ref();

        // 提交评论
        const handleSubmitComment = async () => {
            isCommentSubmitting.value = true;
            const res = await replyComment({
                "paperId": $route.params.id,
                "commentId": props.commentData.id,
                "content": commentContent.value
            });
            if (res.success) {
                message.success('评论成功');
                context.emit('onComment')
                commentContent.value = '';
                isCommentClick.value = false;
            }
            isCommentSubmitting.value = false;
        }

        const onComment = () => {
            context.emit('onComment')
        }

        const handleDeleteComment = () => {
            Modal.confirm({
                content: h('span', {}, [
                    h('span', {}, '是否确认删除评论 '),
                    h('span', { style: { color: '#1890ff' } }, props.commentData.content),
                    '?'
                ]),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('commentid', props.commentData.id );
                    formData.append('paperid', $route.params.id)
                    const res = await deleteComment(formData);
                    if(res.success) {
                        message.success('成功删除')
                        context.emit('onDelete', false);
                    }
                }
            })
        }

        return {
            isSelf,
            getUserHref,
            isCommentClick,
            handleCommentClick,
            commentContent,
            isInputShow,
            inputRef,
            isCommentSubmitting,
            handleSubmitComment,
            onComment,
            handleDeleteComment
        }
    }
}
</script>

<style lang="less" scoped>
.child-comment-ctn {
    &:hover {
        cursor: pointer;
        background: #ebebeb;
    }

}</style>
<style lang="less" scoped>
.comment-header {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 20px;
    .reply-icon {
        &:hover {
            opacity: 1;
        }
    }
    .check-more-operation {
        cursor: pointer;
        &:hover {
            background: #EBEBEB;
        }
    }
}
</style>