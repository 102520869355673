import { render, staticRenderFns } from "./modalChildComment.vue?vue&type=template&id=6e52dbef&scoped=true&"
import script from "./modalChildComment.vue?vue&type=script&lang=js&"
export * from "./modalChildComment.vue?vue&type=script&lang=js&"
import style0 from "./modalChildComment.vue?vue&type=style&index=0&id=6e52dbef&lang=less&scoped=true&"
import style1 from "./modalChildComment.vue?vue&type=style&index=1&id=6e52dbef&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e52dbef",
  null
  
)

export default component.exports